<template>
  <base-material-card
    v-show="parametros.length ? true : false"
    color="primary"
    inline
    :title="titulo"
    icon="mdi-filter"
    class="px-5 py-3"
  >
    <v-form class="pt-5">
      <template v-for="(parametro, i) in parametros">
        <v-row :key="i">
          <v-col cols="12">
            <v-menu
              v-if="isCampoMesDados(parametro.campo)"
              :close-on-content-click="false"
              :nudge-right="100"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="parametro.required ? 'required' : ''"
                >
                  <v-text-field
                    v-model="parametro.dateFormatted"
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                    @blur="parametro.date = parseMonth(parametro.dateFormatted)"
                  >
                    <template v-slot:label>
                      <span v-html="getRawHtmlLabel(parametro)" />
                      <obrigatorio v-if="parametro.required" />
                    </template>
                  </v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="parametro.date"
                type="month"
                :landscape="false"
                @input="
                  updateParametroValor(parametro, parametro.date),
                    (parametro.dateFormatted = formatMonth(parametro.date))
                "
                locale="pt-br"
              />
            </v-menu>
            <v-menu
              v-else-if="parametro.classe == 'data'"
              :close-on-content-click="false"
              :nudge-right="100"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="parametro.required ? 'required' : ''"
                >
                  <v-text-field
                    v-model="parametro.dateFormatted"
                    v-bind="attrs"
                    v-on="on"
                    :error-messages="errors"
                    @blur="parametro.date = parseDate(parametro.dateFormatted)"
                  >
                    <template v-slot:label>
                      <span v-html="getRawHtmlLabel(parametro)" />
                      <obrigatorio v-if="parametro.required" />
                    </template>
                  </v-text-field>
                </validation-provider>
              </template>
              <v-date-picker
                v-model="parametro.date"
                @input="
                  updateParametroValor(parametro, parametro.date),
                    (parametro.dateFormatted = formatDate(parametro.date))
                "
                locale="pt-br"
              />
            </v-menu>
            <template v-else-if="parametro.lista">
              <validation-provider
                v-slot="{ errors }"
                :rules="parametro.required ? 'required' : ''"
              >
                <v-textarea
                  :value="parametro.valor"
                  rows="3"
                  no-resize
                  class="mb-10"
                  hint="Separe com Quebra de Linha"
                  :error-messages="errors"
                  @input="(valor) => updateParametroValor(parametro, valor)"
                >
                  <template v-slot:label>
                    <span v-html="getRawHtmlLabel(parametro)" />
                    <obrigatorio v-if="parametro.required" />
                  </template>
                </v-textarea>
              </validation-provider>
            </template>
            <template
              v-else-if="['inteiro', 'decimal'].includes(parametro.classe)"
            >
              <validation-provider
                v-slot="{ errors }"
                :rules="parametro.required ? 'required' : ''"
              >
                <v-text-field
                  :value="parametro.valor"
                  outlined
                  type="number"
                  :error-messages="errors"
                  @input="(valor) => updateParametroValor(parametro, valor)"
                >
                  <template v-slot:label>
                    <span v-html="getRawHtmlLabel(parametro)" />
                    <obrigatorio v-if="parametro.required" />
                  </template>
                </v-text-field>
              </validation-provider>
            </template>
            <template v-else>
              <validation-provider
                v-slot="{ errors }"
                :rules="parametro.required ? 'required' : ''"
              >
                <v-text-field
                  :value="parametro.valor"
                  outlined
                  :error-messages="errors"
                  @input="(valor) => updateParametroValor(parametro, valor)"
                >
                  <template v-slot:label>
                    <span v-html="getRawHtmlLabel(parametro)" />
                    <obrigatorio v-if="parametro.required" />
                  </template>
                </v-text-field>
              </validation-provider>
            </template>
          </v-col>
        </v-row>
      </template>
    </v-form>
  </base-material-card>
</template>

<script>
import dateFormatterMixins from '@/mixins/dateFormatterMixins';

export default {
  mixins: [dateFormatterMixins],

  props: {
    relatorio: {
      type: Object,
      default: () => ({})
    }
  },

  components: {
    Obrigatorio: () => import('@/components/general/GeneralMarkObrigatorio')
  },

  data: () => ({
    parametros: []
  }),

  created() {
    this.normalizeParams();
  },

  computed: {
    titulo() {
      return `Parâmetros :: ${this.relatorio.nome}`;
    }
  },

  methods: {
    normalizeParams() {
      let relModelo = this.relatorio;
      let obj = {};
      Object.keys(relModelo.parametros_editaveis).forEach((key) => {
        obj = {
          parametro: key,
          nome: relModelo.parametros_editaveis[key].nome,
          campo: relModelo.parametros_editaveis[key].campo,
          criterio: relModelo.parametros_editaveis[key].criterio,
          classe: relModelo.parametros_editaveis[key].classe,
          lista: relModelo.parametros_editaveis[key].lista,
          required:
            relModelo.parametros_editaveis[key].obrigatorio == 1 ? true : false,
          date: '',
          dateFormatted: '',
          valor: ''
        };

        if (relModelo.parametros_editaveis[key].default.trim()) {
          obj = this.setValoresDefault(
            obj,
            relModelo.parametros_editaveis[key].default
          );
        }

        this.parametros.push(obj);
      });
    },

    /**
     * Retorna apenas o nome do campo retirando o prefixo da tabela
     */
    retirarPrefixoNome(nomeCampoTabela) {
      let nomeCampo = nomeCampoTabela.split('.')[1];
      return nomeCampo !== undefined ? nomeCampo : nomeCampoTabela;
    },

    isCampoMesDados(nomeCampo) {
      let campo = this.retirarPrefixoNome(nomeCampo.toUpperCase());
      return ['MES_DADOS', 'MES DADOS', 'MÊS DADOS'].includes(campo);
    },

    updateParametroValor(parametro, valor) {
      if (this.isCampoMesDados(parametro.campo)) {
        parametro.valor = `${valor}-01`;
      } else {
        parametro.valor = valor;
      }
    },

    setValoresDefault(parametro, valoresDefault) {
      if (parametro.classe === 'data') {
        parametro.valor = parametro.date = valoresDefault;

        if (this.isCampoMesDados(parametro.campo)) {
          parametro.dateFormatted = this.formatMonth(valoresDefault);
          parametro.valor = `${valoresDefault}-01`;
        } else {
          parametro.dateFormatted = this.formatDate(valoresDefault);
        }
      } else {
        parametro.valor = valoresDefault;
      }
      return parametro;
    },

    getRawHtmlLabel(value) {
      let criterio = this.translateCriterio(value.criterio);
      return `<b style="color: #003a63 !important"> ${value.nome}</b> ${criterio}`;
    },

    translateCriterio(criterio) {
      switch (criterio.toUpperCase()) {
        case '=':
          return 'é igual a';
        case '<>':
          return 'é diferente de:';
        case '>=':
          return 'é maior ou igual a:';
        case '<=':
          return 'é menor ou igual a:';
        case '>':
          return 'é maior que:';
        case '<':
          return 'é menor que:';
        case 'IN':
          return 'pertence à lista:';
        case 'NOT IN':
          return 'não pertence à lista:';
        case 'ILIKE %X%':
          return 'contém';
        case 'NOT ILIKE %X%':
          return 'não contém';
        case 'ILIKE X%':
          return 'começa com';
        case 'ILIKE %X':
          return 'termina com';
        default:
          return '';
      }
    },

    exportCampos() {
      return this.parametros;
    }
  }
};
</script>
